import React from 'react'
import NavigationDashboard from './dashboard_nav'

export default function Main() {
    return (
        <>
            <NavigationDashboard />
            <div className="innerDiv">
                <h2>Main page for dashboard</h2>
            </div>
        </>

    )
}
