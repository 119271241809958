import React from 'react'
import NavigationDashboard from './dashboard_nav'

export default function Order() {
    return (
        <>
            <NavigationDashboard />
            <div className="innerDiv">
                <h2>Main page for Orders</h2>
            </div>
            
        </>

    )
}
